const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'once-upon-a-time',
	name: 'Once Upon A Time',	
	website: 'https://www.ouat.dk',
	locations: [{
		id: 'copenhagen',
		cityId: 'copenhagen',
		opened: '20240301',
		closed: '20241231',
		address: 'Reberbanegade 3, 2300 København S',
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'alice',
			name: {da: 'Alice', en: 'Alice'},			
			languages: ['da', 'en'],
			teamSizeMax: 6,
			teamSizeMin: 2,
			locations: [{
				locationId: 'copenhagen',
				opened: '20240501',
				closed: '20241231',
				rooms: 1
			}]
		}),
	]
});


export {venueData};