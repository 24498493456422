const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'locked',
	name: 'Locked',
	website: 'https://locked.dk',
	locations: [
		{
			id: 'aalborg',
			cityId: 'aalborg',
			opened: '20221209',
			closed: null,
			address: 'Vesterå 23, 9000 Aalborg C',
			coordinates: {lat: '57.051300', lng: '9.918610'},
		},
		{
			id: 'aarhus', 
			cityId: 'aarhus', 
			opened: '20200401', 
			closed: null,
			address: 'Ny Banegårdsgade 45, 8000 Aarhus',
			coordinates: {lat: '56.150956', lng: '10.205954'},
		},
		{
			id: 'esbjerg',
			cityId: 'esbjerg',
			opened: '20210716',
			closed: '20230930',
			address: 'Skolebakken 137, 6705 Esbjerg',
			coordinates: {lat: '55.478876', lng: '8.484247'},
		},
		{
			id: 'herning',
			cityId: 'herning',
			opened: '20180201',
			closed: null,
			address: 'Højskolevej 11, 7400 Herning',
			coordinates: {lng: '9.0155', lat: '56.133462'},
		},
		{
			id: 'hjorring',
			cityId: 'hjorring',
			opened: '20221007',
			closed: '20230930',
			address: 'Frederikshavnsvej 80B, 9800 Hjørring',
			coordinates: {lng: '10.013160', lat: '57.455210'},
		},
		{
			id: 'holstebro',
			cityId: 'holstebro',
			opened: '20191003',
			closed: null,
			address: 'Østergade 18, 7500 Holstebro',
			coordinates: {lng: '8.620631', lat: '56.358089'},
		},
		{
			id: 'horsens',
			cityId: 'horsens',
			opened: '20220527',
			closed: null,
			address: 'Fussingsvej 8, 8700 Horsens',
		},
		{
			id: 'kolding',
			cityId: 'kolding',
			opened: '20200611',
			closed: null,
			address: 'Dalbygade 40B, 6000 Kolding',
			coordinates: {lat: '55.483922', lng: '9.475909'},
		},
		{
			id: 'naestved',
			cityId: 'naestved',
			opened: '20221014',
			closed: '20240501',
			address: 'Maglemølle 18, 4700 Næstved',
		},
		{
			id: 'odense',
			cityId: 'odense',
			opened: '20201002',
			closed: null,
			address: 'Kongensgade 66, 1. sal, 5000 Odense',
			coordinates: {lng: '10.37964', lat: '55.39801'},
		},
		{
			id: 'randers',
			cityId: 'randers',
			opened: '20190802',
			closed: null,
			address: 'Nørregade 7, 8900 Randers',
			coordinates: {lat: '56.461852', lng: '10.035464'},
		},
		{
			id: 'roskilde',
			cityId: 'roskilde',
			opened: '20230804',
			closed: null,
			address: 'Københavnsvej 26, 4000 Roskilde',
		},

	],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'chernobyl',
			name: {da: 'Chernobyl', en: 'Chernobyl'},
			languages: ['da', 'en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 80,
			locations: [
				{
					locationId: 'kolding',
					opened: '20200611',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'randers',
					opened: '20190611',
					closed: null,
					rooms: 1
				}
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'crime-scene',
			name: {da: 'Gerningsstedet', en: 'Crime Scene'},
			languages: ['da', 'en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 80,
			locations: [
				{
					locationId: 'aarhus',
					opened: '20210521',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'herning',
					opened: '20201030',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'kolding',
					opened: '20200611',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'odense',
					opened: '20201002',
					closed: '20240201',
					rooms: 1
				},
				{
					locationId: 'randers',
					opened: '20190802',
					closed: null,
					rooms: 1
				}
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'jigsaw',
			name: {da: 'Jigsaw', en: 'Jigsaw'},
			languages: ['da', 'en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 80,
			locations: [{
				locationId: 'herning',
				opened: '20201030',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'kidnapped',
			name: {da: 'Kidnapped', en: 'Kidnapped'},
			languages: ['da', 'en'],
			teamSizeMin: 3,
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 80,
			tagIds: ['battle-room'],
			locations: [
				{
					locationId: 'aalborg',
					opened: '20221209',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'aarhus',
					opened: '20210521',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'holstebro',
					opened: '20191003',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'kolding',
					opened: '20200611',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'odense',
					opened: '20201002',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'randers',
					opened: '20190802',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'roskilde',
					opened: '20230804',
					closed: null,
					rooms: 1
				}
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'prison-break',
			name: {da: 'Prison Break', en: 'Prison Break'},
			languages: ['da', 'en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 70,
			locations: [
				{
					locationId: 'esbjerg',
					opened: '20210716',
					closed: '20230930',
					rooms: 1
				},
				{
					locationId: 'herning',
					opened: '20201030',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'odense',
					opened: '20201002',
					closed: null,
					rooms: 1
				}
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'sabotage',
			name: {da: 'Sabotage', en: 'Sabotage'},
			languages: ['da', 'en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 70,
			tagIds: ['beginners'],
			description: {
				da: `I Sabotage skal man klare en mission under Anden Verdenskrig. De meget forskelligt indrettede rum og det høje tempo, man føres igennem dem og historien, skaber en rigtig god atmosfære ladet med følelsen af vigtighed og alarm. Opgaverne er gode og varierede, og på trods af det høje tempo er hele flowet robust robust og efterlader ingen tvivl om, hvad man skal gøre. Vi oplevede en lille fejl med en elektrisk lås, men det var ret tydeligt, hvad der var sket, og det påvirkede ikke vores fremgang. Vi synes, det er et rigtig godt rum til begyndere. Eller til erfarne spillere, der vil prøve at slå os af pinden med den bedste tid. ;)`,
				en: `In Sabotage you have to complete an important mission set during World War II. The very different designs of the rooms you pass through as well as the high speed at which you (and the story) move along create a great atmosphere with a very real sense of urgency and alarm. The puzzles are good and diverse, and the whole flow - although fast paced - is solid and leaves little room for confusion. We did experience a small malfunction with an electric lock, but it was pretty obvious what happened, and it didn't affect our progress. We think it is a great room for beginners. Or for experienced players who wants to try to knock us down from the top of the 'best times' list. ;)`
			},
			locations: [{
				locationId: 'randers',
				opened: '20190802',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'swat',
			name: {da: 'S.W.A.T.', en: 'S.W.A.T.'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAge: 10,
			difficulty: 80,
			locations: [{
				locationId: 'holstebro',
				opened: '20191003',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-expedition',
			name: {da: 'The Expedition', en: 'The Expedition'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAge: 10,
			difficulty: 60,
			locations: [{
				locationId: 'holstebro',
				opened: '20191003',
				closed: '20200930',
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'the-undead',
			name: {da: 'The Undead', en: 'The Undead'},
			languages: ['da', 'en'],
			teamSizeMin: 2,
			teamSizeMax: 6,
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 80,
			locations: [
				{
					locationId: 'aalborg',
					opened: '20221209',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'aarhus',
					opened: '20210521',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'esbjerg',
					opened: '20210716',
					closed: '20230930',
					rooms: 1
				},
				{
					locationId: 'herning',
					opened: '20201030',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'hjorring',
					opened: '20221007',
					closed: '20230930',
					rooms: 1
				},
				{
					locationId: 'holstebro',
					opened: '20201107',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'horsens',
					opened: '20220527',
					closed: null,
					rooms: 3
				},
				{
					locationId: 'kolding',
					opened: '20200925',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'naestved',
					opened: '20221014',
					closed: '20240501',
					rooms: 2
				},
				{
					locationId: 'odense',
					opened: '20201002',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'randers',
					opened: '20200611',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'roskilde',
					opened: '20230804',
					closed: null,
					rooms: 2
				}
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'zodiac',
			name: {da: 'Zodiac', en: 'Zodiac'},
			languages: ['en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 80,
			teamSizeMin: 2,
			teamSizeMax: 6,
			locations: [
				{
					locationId: 'aarhus',
					opened: '20231009',
					closed: null,
					rooms: 2
				},
				{
					locationId: 'odense',
					opened: '20240528',
					closed: null,
					rooms: 1
				}
				
			]
		}),
		Object.assign({}, roomTemplate, {
			id: 'undercover',
			name: {da: 'Undercover', en: 'Undercover'},
			languages: ['da', 'en'],
			minimumAge: 10,
			minimumAgeNoAdult: 15,
			difficulty: 55,
			teamSizeMin: 2,
			teamSizeMax: 6,
			locations: [
				{
					locationId: 'horsens',
					opened: '20220915',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'aalborg',
					opened: '20230322',
					closed: null,
					rooms: 1
				},
				{
					locationId: 'roskilde',
					opened: '20240501',
					closed: null,
					rooms: 1
				}
			]
		}),
	]
});


export {venueData};